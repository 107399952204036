<template>
  <div class="default-toggle" :class="[size]">
    <label class="toggle" :class="{ disabled: disabled }">
      <input
        type="checkbox"
        @change="updateValue($event.target.checked)"
        :checked="value"
        :disabled="disabled"
      />
      <span class="slider"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "DefaultToggle",
  props: ["value", "disabled", "size"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.disabled {
  input:checked + .slider {
    background-color: $fog;
    border: 1px solid $mist;
    cursor: default;

    &:hover {
      background-color: $fog;
      border: 1px solid $mist;

      &:before {
        background-color: white;
      }
    }
  }
  input + .slider {
    background-color: $fog;
    border: 1px solid $mist;
    cursor: default;

    &:hover {
      background-color: $fog;
      border: 1px solid $mist;

      &:before {
        background-color: white;
      }
    }
  }
}

.toggle {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 28px;
}

/* Hide default HTML checkbox */
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid $blizzard;
  background: $blizzard;

  &:hover {
    border: 1px solid $midnight;
    background: $midnight;

    &:before {
      background-color: white;
    }
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: white;
}

input:checked + .slider {
  background-color: $twilight;
  border: 1px solid $twilight;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider {
  border-radius: 34px;
}

.slider:before {
  border-radius: 50%;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .large {
    .toggle {
      width: 72px;
      height: 44px;
    }
    .slider:before {
      height: 32px;
      width: 32px;
      left: 4px;
      bottom: 5px;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(30px);
      -ms-transform: translateX(30px);
      transform: translateX(30px);
    }
  }
}
</style>
